var App = function () {

    //Datepicker
    var datepicker = function () {
        $("[data-provide='datepicker']").datepicker({
            language: 'it'
        });
    }

    //Tooltip
    var tooltip = function () {
        $("[data-toggle='tooltip']").tooltip();
    }

    //switch opzioni
   var switchClass =  function () {
        $("input[data-op='switch']").on("change", function () {
            var target = $(this).attr("data-target");

            if ($(this).prop("checked")) {
                $("." + target).removeClass("hide").show(500);
            } else {
                $("." + target).hide(300);
            }
        });
    }

    //ui-jq
    var ui_jq = function () {
        $("[ui-jq]").each(function () {

            var self = $(this);

            var options = eval('[' + self.attr('ui-options') + ']');

            if ($.isPlainObject(options[0])) {
                options[0] = $.extend({}, options[0]);
            }

            self[self.attr('ui-jq')].apply(self, options);
        });
    }

    //Tabelle
    var dataTable = function() {

        $.fn.dataTable.moment('D/M/Y');

        // datatable
        $('[data-ride="elenco"]').each(function() {
            $(this).dataTable( {
                "language": { url: "/assets/private/js/plugins/dataTables/dt_it.json" },
                "iDisplayLength": 25,
                "bStateSave": true,
                oTableTools: "show",
                tableTools: {
                    "sSwfPath": "/assets/private/js/plugins/dataTables/extensions/TableTools/swf/copy_csv_xls_pdf.swf"
                },
                "sDom": "<'row'<'col-sm-8'lT><'col-sm-4'f>r>t<'row'<'col-sm-6'i><'col-sm-6'p>>",
                "sPaginationType": "full_numbers",
                "fnDrawCallback": function( oSettings ) {},
                "fnInitComplete": function (oSettings, json) {
                    $(this).parents('.dataTables_wrapper').find('select').selectize();
                    $(this).parents('.dataTables_wrapper').find('.dataTables_filter input[type="search"]').prop('placeholder', 'Cerca per parole chiave...').addClass('form-control').removeClass('input-sm');
                }
            });

            //Loading tabella
            $("#load-table").hide();
            $('[data-ride="elenco"]').fadeIn(400);

        });

    }

    // easypie
    var easypie = function(){
        $('.easypiechart').each(function(){
            var $this = $(this),
                $data = $this.data();
            $(this).easyPieChart($data);
        });
    };

    //ajaxSubmit
    var ajaxSubmit = function () {

        //Azioni

        $("a[rel='ajaxSubmit']").on("click", function (e) {
            e.preventDefault();
            var btn = $(this);

            var id = btn.data("id");
            var op = btn.data("op");
            var target = btn.data("target");
            var status = btn.data("status");
            var token = $("meta[name='token']").attr("content");
            var page = window.location.href;

            var ok = function () {
                switch (op) {
                    case "elimina":
                    case "archivia":
                    case "ripristina":

                        $("#idR_" + id).hide(500, function () {
                            $.post("/actions/status", {_token: token, id: id, op: op, target: target, status: 0}, function (data) {
                                toastr[data.type](data.status);
                            }, 'json');
                        });
                        break;

                    default:
                        $.post("/actions/status", {_token: token, id: id, op: op, target: target, status: status}, function (data) {
                            $("#idR_" + id).load(page + " #idR_" + id + " >*", function () {
                                // Shadowbox.clearCache();
                                // Shadowbox.setup();
                                ajaxSubmit();
                            });
                            toastr[data.type](data.status);
                        }, 'json');
                        break;
                }
                $.fallr("hide");
            };

            $.fallr("show", {
                buttons: {
                    button1: {
                        text: "Conferma",
                        danger: true,
                        onclick: ok
                    },
                    button2: {
                        text: "Annulla",
                        onclick: function () {
                            $.fallr("hide")
                        }
                    }
                },
                content: "<p>Sicuro di voler confermare l'operazione?</p>",
                icon: "warning"
            });
            return false;
        });


    }


    /* INIT Functions ---------------------------------------------- */

    return {

        //main function to initiate the module
        init: function () {

            moment.lang('it');

            datepicker();
            tooltip();
            switchClass();
            ui_jq();
            ajaxSubmit();
            easypie();
            dataTable();


        },

        slugify: function (text) {

            return text.toString().toLowerCase()
                .replace(/\s+/g, '-')           // Replace spaces with -
                .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
                .replace(/\-\-+/g, '-')         // Replace multiple - with single -
                .replace(/^-+/, '')             // Trim - from start of text
                .replace(/-+$/, '');            // Trim - from end of text
        },

        setPage: function (page) {

            var active_page = $("nav").find("a[data-id='" + page + "']");

            active_page.parent().addClass("current active");
            active_page.parent().parent().parent().addClass("current active");
            active_page.parent().parent().parent("li").find(".dropdown-toggle").addClass("active");
            active_page.parent().parent().slideDown(200);

        },

        getURLParameter: function (paramName) {

            var searchString = window.location.search.substring(1),
                i, val, params = searchString.split("&");

            for (i = 0; i < params.length; i++) {
                val = params[i].split("=");
                if (val[0] == paramName) {
                    return unescape(val[1]);
                }
            }
            return null;
        },

        setIframeHeight: function (height) {
            $('.modal-content iframe').animate({'height': height});
        },

        closeModal: function () {
            $('#modalForm').modal('hide');
        },

        resizeIframe: function (iframe) {
            iframe.height = iframe.contentWindow.document.body.scrollHeight + "px";
        }


    };

}();