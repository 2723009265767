//Login ------------------------------------------------

var Login = function () {

    return {

        //main function to initiate the module
        init: function (response) {


        }

    };

}();